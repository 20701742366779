import { Grid } from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useAppSelector, useAuth } from "hooks/hooks";
import { ReportCreationStatus } from "utilities/constants/enums";
import { DATA_GENERATION_MAX_DURATION_IN_MINUTES } from "utilities/dataGenerationStatus";
import { getLoadingStatusIcon } from "utilities/loadingStatus";
import { reportsSelectors, selectIsDataGenerationComplete } from "features/reports";
import { fetchReportsCreationStatus } from "actions/reportActions";
import { useEffect } from "react";
import clsx from "clsx";

import styles from "./DataGenerationStatuses.module.scss";

interface DataGenerationStatusViewInputProps {
	portalHasAccess: boolean;
	setIsPreparingData: (isPreparingData: boolean) => void;
	timeElapsed: number;
}

export const DataGenerationStatusView = ({
	portalHasAccess,
	setIsPreparingData,
	timeElapsed,
}: DataGenerationStatusViewInputProps) => {
	const tasks = useAppSelector(reportsSelectors.selectAll);
	const { dispatch, auth } = useAuth();

	const dataGenerationComplete = useAppSelector(selectIsDataGenerationComplete);
	const hasReachedMaxDuration = timeElapsed > (DATA_GENERATION_MAX_DURATION_IN_MINUTES + 1) * 60;

	useEffect(() => {
		const correctTimeToTryFetch = timeElapsed === 1 || timeElapsed % 10 === 0;

		if (correctTimeToTryFetch && !dataGenerationComplete && !hasReachedMaxDuration) {
			dispatch(fetchReportsCreationStatus({ auth }));
		}
	}, [timeElapsed, dataGenerationComplete, hasReachedMaxDuration, dispatch, auth]);

	if (dataGenerationComplete || hasReachedMaxDuration) {
		setIsPreparingData(false);
	}

	const getIcon = (status: ReportCreationStatus) => {
		if (!portalHasAccess) {
			return <QueryBuilderIcon fontSize="large" />;
		}

		return getLoadingStatusIcon(status);
	};

	// Define the sort order mapping
	const statusSortOrder: { [key in ReportCreationStatus]: number } = {
		[ReportCreationStatus.Passed]: 1,
		[ReportCreationStatus.Running]: 2,
		[ReportCreationStatus.Failed]: 3,
		[ReportCreationStatus.Skipped]: 4,
		[ReportCreationStatus.NotRun]: 5,
	};

	// Sort the tasks based on the status order
	const sortedTasks = [...tasks].sort((a, b) => {
		return statusSortOrder[a.Status] - statusSortOrder[b.Status];
	});

	return (
		<Grid container direction="column" alignItems="center">
			{sortedTasks.map(({ Status, Name, DisplayName }) => (
				<Grid
					container
					direction="row"
					alignItems="center"
					className={clsx(styles.taskRow, {
						[styles.taskRowFaded]: Status === ReportCreationStatus.NotRun,
					})}
					key={Name}
				>
					<Grid
						item
						className={clsx({
							[styles.whiteIcon]:
								Status === ReportCreationStatus.NotRun ||
								Status === ReportCreationStatus.Running ||
								Status === ReportCreationStatus.Skipped,
							[styles.greenIcon]: Status === ReportCreationStatus.Passed,
							[styles.redIcon]: Status === ReportCreationStatus.Failed,
						})}
					>
						{getIcon(Status)}
					</Grid>
					<Grid item ml={3}>
						<span>{DisplayName}</span>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};
