import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { createReports, fetchReportsCreationStatus } from "actions/reportActions";
import { RootState } from "store";
import { ReportCreationTask } from "types";
import { ReportCreationStatus } from "utilities/constants/enums";

const reportsAdapter = createEntityAdapter<ReportCreationTask>({
	selectId: (task) => task.Name,
});

const reportsSlice = createSlice({
	name: "reports",
	initialState: reportsAdapter.getInitialState({
		reports: [] as ReportCreationTask[],
		startedReportGeneration: false,
		dataGenerationIsComplete: false,
		isLoading: true,
		isFetching: false,
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(createReports.pending, (state) => {
			state.startedReportGeneration = true;
		});

		builder
			.addCase(fetchReportsCreationStatus.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchReportsCreationStatus.fulfilled, (state, { payload }) => {
				const fetchedTasks = payload as ReportCreationTask[];
				const tasks = fetchedTasks.filter((task) => task.ShowInPortal);
				const generationHasBeenStarted = checkIfGenerationHasBeenStarted(tasks);
				const dataGenerationIsComplete = checkIfDataGenerationIsComplete(tasks);
				state.startedReportGeneration = generationHasBeenStarted;
				state.dataGenerationIsComplete = dataGenerationIsComplete;
				reportsAdapter.upsertMany(state, tasks);
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchReportsCreationStatus.rejected, (state) => {
				state.isFetching = false;
				state.isLoading = false;
				state.startedReportGeneration = false;
				state.dataGenerationIsComplete = false;
			});
	},
});

export const selectReports = (state: RootState) => state.reports;
export const selectReportGenerationHasBeenStarted = (state: RootState) =>
	state.reports.startedReportGeneration;
export const selectIsDataGenerationComplete = (state: RootState) =>
	state.reports.dataGenerationIsComplete;
export const reportsSelectors = reportsAdapter.getSelectors(selectReports);

export default reportsSlice.reducer;

const checkIfGenerationHasBeenStarted = (tasks: ReportCreationTask[]) => {
	if (tasks.length === 0) {
		return false;
	}

	return tasks.some((task) =>
		[
			ReportCreationStatus.Failed,
			ReportCreationStatus.Running,
			ReportCreationStatus.Passed,
			ReportCreationStatus.Skipped,
		].includes(task.Status),
	);
};

const checkIfDataGenerationIsComplete = (tasks: ReportCreationTask[]) => {
	if (tasks.length === 0) {
		return false;
	}

	return tasks.every((task) =>
		[
			ReportCreationStatus.Failed,
			ReportCreationStatus.Passed,
			ReportCreationStatus.Skipped,
		].includes(task.Status),
	);
};
