import { getServicePrincipal } from "components/api/servicePrincipal";
import { ensureAnyPortalAdmins } from "components/api/roles";
import { useEffect, useState } from "react";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import styles from "./SettingUpPortalDialog.module.scss";
import { createReport } from "components/api/createReport";
import { useAppSelector, useAuth } from "hooks/hooks";
import { clearPreviouslySetAccessTokens } from "utilities/tokenUtils/sessionStorageHelper";
import { selectRoles, setRoles } from "features/roles";
import { DataGenerationStatusView } from "./DataGenerationStatuses/DataGenerationStatuses";
import { selectReportGenerationHasBeenStarted } from "features/reports";
import { PrimaryButton } from "components/Common/Buttons/Button";

interface SettingUpPortalDialogProps {
	isMissingPermissions: boolean;
	userRoles: {
		userHasCorrectRoles: boolean;
		checkingUserRoles: boolean;
	};
	setAnyPortalAdmins: (isOpen: boolean) => void;
	setOpenSettingUpPortalDialog: (isOpen: boolean) => void;
	setPortalIsInTenant: (isOpen: boolean) => void;
}

const SettingUpPortalDialog = ({
	isMissingPermissions,
	userRoles,
	setAnyPortalAdmins,
	setOpenSettingUpPortalDialog,
	setPortalIsInTenant,
}: SettingUpPortalDialogProps) => {
	const {
		auth: { instance, account, authConfig },
		dispatch,
	} = useAuth();
	const roles = useAppSelector(selectRoles);

	// In order to let the user focus solely on the dialog, we hide the rest of the page
	const [timeElapsed, setTimeElapsed] = useState<number>(0);
	const isLoadingPermissions =
		timeElapsed <= 5 || isMissingPermissions || userRoles.checkingUserRoles;
	const isLoadingAdminRights = timeElapsed <= 30 || !roles?.includes("Admin");

	const [portalHasAccess, setPortalHasAccess] = useState<boolean>(false);
	const [isPreparingData, setIsPreparingData] = useState<boolean>(false);

	const disableActions = isLoadingPermissions || isLoadingAdminRights || isPreparingData;
	const dataGenerationHasBeenStarted = useAppSelector(selectReportGenerationHasBeenStarted);

	useEffect(() => {
		// If portal has access and data generation has not already been started, we create the reports
		if (portalHasAccess && !dataGenerationHasBeenStarted) {
			createReport({
				instance,
				account,
				authConfig,
				url: authConfig.REPORTS_API_ENDPOINTS.CREATE_REPORTS(),
			});
		}
	}, [portalHasAccess, instance, account, authConfig, dataGenerationHasBeenStarted]);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeElapsed((timeElapsed) => timeElapsed + 1);
		}, 1000);

		const ensureSetupReady = async () => {
			const servicePrincipal = await getServicePrincipal({
				instance,
				account,
				authConfig,
				appId: instance.getConfiguration().auth.clientId,
			});

			if (!servicePrincipal) {
				return;
			}

			setPortalHasAccess(true);

			const portalAdmins = await ensureAnyPortalAdmins(instance, account, authConfig);

			if (!portalAdmins) {
				return;
			}

			// Force update admin rights of logged in user once portal admins are set
			dispatch(setRoles(["Admin"]));

			setAnyPortalAdmins(true);
		};

		// Try to ensure setup is ready each 5 seconds if portal admins is not set
		if (
			(timeElapsed % 5 === 0 || timeElapsed === 5) && // check first after 5 seconds, then every 5 seconds
			!roles?.includes("Admin") && // don't check if user already has admin rights
			!userRoles.checkingUserRoles && // if directory roles check is finished
			userRoles.userHasCorrectRoles // and the user has correct roles
		) {
			ensureSetupReady();
		}

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeElapsed]);

	const handleCloseDialog = () => {
		// We clear the previously set access tokens, as these have old roles
		clearPreviouslySetAccessTokens();
		// If primaryAction is enabled in dialog below, it means that:
		// 1. Portal is in the tenant and
		// 2. Portal admins are set
		// 3. We've cleared all old access tokens, so we can let the user enter the portal with the new roles
		setPortalIsInTenant(true);
		setOpenSettingUpPortalDialog(false);
	};

	const handleLogout = () => {
		instance.logoutRedirect({
			account: account,
		});
	};

	const content = () => {
		const missingUserAccess = !userRoles.checkingUserRoles && !userRoles.userHasCorrectRoles;
		if (missingUserAccess) {
			return (
				<PrimaryDialog
					title={"Missing user access"}
					primaryAction={handleLogout}
					size="medium"
					primaryButtonText={"Logout"}
					removeContentMarginAndPadding={true}
				>
					<Grid container p={3} pb={0} display={"flex"} flexDirection={"column"}>
						<Grid container direction="row" alignItems="center">
							<Grid item>
								<div>
									It looks like <b>{account?.username ?? "you"}</b> don't have
									access to configure this portal. Please check if you have logged
									in with an <b>azure global administrator </b>account or contact
									your administrator for help.
								</div>
							</Grid>
						</Grid>
					</Grid>
				</PrimaryDialog>
			);
		}

		return (
			<Grid container className={styles.fullScreenSetup}>
				<Grid container className={styles.ingressContainer}>
					{
						<>
							<p>We are making the portal ready for you, please wait</p>
						</>
					}
				</Grid>
				<Grid container className={styles.loadingContainer}>
					<Grid container className={styles.loadingLine}>
						<Grid item>
							{!isLoadingAdminRights && (
								<CheckCircle fontSize="large" color="success" />
							)}
							{isLoadingAdminRights && (
								<div className={styles.loadingIcon}>
									<CircularProgress size={30} color="secondary" />
								</div>
							)}
						</Grid>
						<Grid item ml={3}>
							<span>Configuring portal admin access</span>
						</Grid>
					</Grid>
					<DataGenerationStatusView
						portalHasAccess={portalHasAccess}
						setIsPreparingData={setIsPreparingData}
						timeElapsed={timeElapsed}
					/>
					<PrimaryButton
						size="medium"
						action={handleCloseDialog}
						isDisabled={disableActions}
						variantStyle="contained"
						text={"Enter portal"}
						marginTop={3}
					/>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			{timeElapsed > 0.3 && (
				<Backdrop open={true} className={styles.backdrop}>
					{content()}
				</Backdrop>
			)}
		</>
	);
};

export { SettingUpPortalDialog };
