import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./features/hardware/products";
import mailDomainsReducer from "./features/mailDomains";
import departmentsReducer from "./features/departments";
import entitlementsReducer from "./features/entitlements";
import licensesReducer from "./features/licenses/licenses";
import licensePricesReducer from "./features/licenses/licensePrices";
import licenseNamesReducer from "./features/licenses/licenseNames";
import scheduledActionsReducer from "./features/scheduledActions";
import usersReducer from "./features/users";
import customerReducer from "./features/customer";
import requesterReducer from "./features/requesters";
import costsReducer from "./features/costs";
import invoiceReducer from "./features/invoices";
import aggregatedDataReducer from "./features/aggregatedData";
import securityIncidentReducer from "./features/securityIncidents";
import serviceStatusReducer from "./features/serviceStatuses";
import appRoleAssignmentReducer from "features/appRoleAssignments";
import archivalSettingsReducer from "features/archivalSettings";
import rolesReducer from "features/roles";
import groupsReducer from "features/groups";
import ordersReducer from "features/orders";
import hardwareBundles from "features/hardware/hardwareBundles";
import hardwarePage from "features/hardware/hardwarePage";
import recommendationsReducer from "features/recommendations";
import lockedDialogReducer from "features/lockedDialog";
import licenseAssignmentErrorsReducer from "features/licenses/licenseAssignmentErrors";
import devicesAdapter from "features/devices";
import userDrawerReducer from "features/user";
import brandingSliceReducer from "features/branding";
import snackbarSliceReducer from "features/snackbars";
import hardwareApproversSlice from "features/hardware/hardwareApprovers";
import accesSlice from "features/access";
import reportsSlice from "features/reports";

export const store = configureStore({
	reducer: {
		products: productsReducer,
		mailDomains: mailDomainsReducer,
		departments: departmentsReducer,
		entitlements: entitlementsReducer,
		licenses: licensesReducer,
		licensePrices: licensePricesReducer,
		licenseNames: licenseNamesReducer,
		licenseAssignmentErrors: licenseAssignmentErrorsReducer,
		scheduledActions: scheduledActionsReducer,
		recommendations: recommendationsReducer,
		groups: groupsReducer,
		users: usersReducer,
		customer: customerReducer,
		requesters: requesterReducer,
		costs: costsReducer,
		invoices: invoiceReducer,
		aggregatedData: aggregatedDataReducer,
		securityIncidents: securityIncidentReducer,
		serviceStatuses: serviceStatusReducer,
		appRoleAssignments: appRoleAssignmentReducer,
		archivalSettings: archivalSettingsReducer,
		roles: rolesReducer,
		orders: ordersReducer,
		hardwareBundles: hardwareBundles,
		hardwarePage: hardwarePage,
		hardwareApprovers: hardwareApproversSlice,
		lockedDialog: lockedDialogReducer,
		devices: devicesAdapter,
		userDrawer: userDrawerReducer,
		branding: brandingSliceReducer,
		snackbar: snackbarSliceReducer,
		access: accesSlice,
		reports: reportsSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: ["invoices/fetch/fulfilled"],
			},
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
