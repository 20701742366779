import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { CircularProgress } from "@mui/material";
import { ActionLoadingStatus, ReportCreationStatus } from "./constants/enums";

export const getLoadingStatusIcon = (status: ReportCreationStatus | ActionLoadingStatus) => {
	console.log("status", status === ReportCreationStatus.Running, status);
	switch (status) {
		case ReportCreationStatus.NotRun || ActionLoadingStatus.NOT_STARTED:
			return <QueryBuilderIcon fontSize="large" />;
		case ReportCreationStatus.Passed || ActionLoadingStatus.COMPLETED:
			return <CheckCircleIcon fontSize="large" color="success" />;
		case ReportCreationStatus.Running || ActionLoadingStatus.IN_PROGRESS:
			return (
				<div
					style={{
						paddingTop: "3px",
						paddingBottom: "2px",
						paddingRight: "2px",
						paddingLeft: "3px",
					}}
				>
					<CircularProgress size={30} />
				</div>
			);
		case ReportCreationStatus.Failed || ActionLoadingStatus.FAILED:
			return <ErrorOutlineIcon fontSize="large" color="error" />;
		default:
			return <QueryBuilderIcon fontSize="large" />;
	}
};
