import { createAsyncThunk } from "@reduxjs/toolkit";
import { REPORTS_FIRST_RUN_STATUS_FOLDER_PATH, SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";
import { ReportCreationTask } from "types";

export const createReports = createAsyncThunk(
	"reports/create",
	apiPost(
		(authConfig) => authConfig.REPORTS_API_ENDPOINTS.CREATE_REPORTS(),
		[SCOPES.REPORTS_WRITE],
	),
);

export const fetchReportsCreationStatus = createAsyncThunk(
	"reports/fetchReportsCreationStatus",
	apiGet<ReportCreationTask[]>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(
				REPORTS_FIRST_RUN_STATUS_FOLDER_PATH,
			),
		[SCOPES.DATA_LAKE_READ],
	),
);
